import {projects} from './projects';
import {title, shortBio, headerImage, contact} from './general';
import {profile, aboutPageTitle, sections, aboutImages} from './about';

const general =  {
  title,
  shortBio,
  headerImage,
  contact,
}

const about = {
  profile,
  pageTitle: aboutPageTitle,
  sections,
  aboutImages,
}

export {general, projects, about};
