export const projects = [
  {
    title: "gofast",
    url: "//github.com/johnaoss/gofast",
    description:
      "gofast is a macOS menu bar app to check your network speeds written in Go. This checks fast.com for your current internet speed and reports back. This project required a fair bit of low-level macOS knowledge in order to successfully implement."
  },
  {
    title: "linkedin-api",
    url: "//github.com/johnaoss/linkedin-api",
    description:
      "This project functions as a pure Go interface for Linkedin's v1 REST API. Currently, this provides a lightweight, documented interface to get a user's posts & profile data into a native marshallable Go struct. Requests are made secure by using OAuth2.0 authenticated requests to LinkedIn's servers."
  },
  {
    title: "htpasswd",
    url: "//github.com/johnaoss/htpasswd",
    description:
      "htpasswd provides Go-specific partial implementation of Apache's original htpasswd utility. This includes an optimized version of the Apache-specific APR1 hashing algorithm, which can be used completely separately from the rest of the project."
  },
  // {
  //   title: "supports-color",
  //   url: "//github.com/johnaoss/supportscolor",
  //   description:
  //     "This package provides a simple interface to query the level of color support found in a given terminal. This allows developers to know their users are seeing beautiful outputs, instead of hard to read alternatives."
  // }
];
