import React, { Component } from "react";
import classNames from "classnames";
import * as styles from "./Page.module.scss";

import { general, about } from "./content";
import { Footer, NavHeader } from "../Components";

class About extends Component {
  render() {
    const aboutTextStyles = classNames(styles.LongBio, styles.narrowText);
    const blockWithSecondaryBackground = classNames(
      styles.Block,
      styles.withSecondaryBackground
    );
    const secondarySectionStyle = classNames(aboutTextStyles, styles.SecondarySection);
    return (
      <>
        <NavHeader title={about.pageTitle} />
        <section className={styles.Block}>
          <div className={styles.BlockContent}>
            <div className={styles.Introduction}>
              <img
                className={styles.ProfilePhoto}
                src={about.profile}
                alt="john"
              />
            </div>
            <div className={aboutTextStyles}>
              <p>{about.sections.intro}</p>
            </div>
          </div>
        </section>
        <section className={blockWithSecondaryBackground}>
          <div className={styles.BlockContent}>
            <h3 className={styles.ContentTitle}>
              {about.sections.secondarySectionTitle}
            </h3>
            <div className={secondarySectionStyle}>
              <p>{about.sections.secondarySectionContent}</p>
            </div>
            {/* <div className={styles.ImageGallery}>
              {}
              <div className={styles.ImageInGallery}>
                <img
                  className={styles.ImageInContainer}
                  src={about.aboutImages.first}
                  alt="john"
                />
              </div>
              <div className={styles.ImageInGallery}>
                <img
                  className={styles.ImageInContainer}
                  src={about.aboutImages.second}
                  alt="john"
                />
              </div>
              <div className={styles.ImageInGallery}>
                <img
                  className={styles.ImageInContainer}
                  src={about.aboutImages.third}
                  alt="john"
                />
              </div>
            </div> */}
          </div>
        </section>
        <Footer contact={general.contact} />
      </>
    );
  }
}

export default About;
