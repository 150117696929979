import React from "react";
import * as styles from "./Footer.module.scss";

export const Footer = ({ contact }) => {
  return (
    <section className={styles.Footer}>
      <div className={styles.FooterConent}>
        <div className={styles.Tag}>
          <p>SOCIAL LINKS</p>
        </div>
        <div className={styles.Social}>
          <div className={styles.SocialLinks}>
            {contact.socials.map((social) => (
              <a href={social.url}>
              <p>{social.name}</p>
            </a>
            ))}
          </div>
          <div className={styles.Email}>
            <p className={styles.EmailSub}>Email me at</p>
            <p className={styles.ActualEmail}>{contact.email}</p>
          </div>
        </div>
      </div>
    </section>
  );
};