export const profile = "./john-circle.png";
export const aboutPageTitle = "About";
export const sections = {
  intro: "Hi there, my name is John Oss. I'm a student at the University of Waterloo, currently studying Computer Science. I'm passionate about building software, specifically in the areas of developer tooling, infrastrucutre, and the web. I'm currently seeking internship opportunities for May-August 2021",
  secondarySectionTitle: "Friends, & Mentors",
  secondarySectionContent:
    "I’ve had the privilege of meeting some amazing designers, developers, and managers the past few years who all come from very amazing areas of expertise. I’ve learned a lot from working with them and I wanted to honor that. I'd like to thank Calum Patrick, Danny Kong, and Richard Shi for reigniting my passion for software, and always being great people to work with. I would not be here today were it not for their help and guidance."
};
export const aboutImages = {
  // Max 3 Images for grid layout
  // Images must have 1:1 aspect ratio
  // first: "about-first.png",
  // second: "about-second.png",
  // third: "about-third.png"
};
