export const title = 'John Oss — Software Developer';
export const shortBio = "Hi there, I'm John. I'm currently studying Computer Science at the University of Waterloo, and I'm passionate about improving peoples experiences through software."
export const headerImage = './header.png';
export const contact = {
  socials: [
    {
      name: "Stackoverflow",
      url: "//stackoverflow.com/users/2911436/john-oss"
    },
    {
      name: "GitHub",
      url: "//github.com/johnaoss"
    },
    {
      name: "LinkedIn",
      url: "//linkedin.com/in/johnaoss/"
    },
    {
      name: "Twitter",
      url: "//twitter.com/johnaoss"
    },
  ],
  email: "john@johnoss.com"
};
