import React from "react";
import * as styles from "./NavHeader.module.scss";

export const NavHeader = ({ title }) => {
  return (
      <section className={styles.HeaderBlock}>
        <div className={styles.HeaderBlockContent}>
          <div className={styles.BackNav}>
            <a href="/">
              <img src="/left.png" />
              <h3>Home</h3>
            </a>
          </div>
          <div className={styles.NavHeader}>
            <h3 className={styles.PageTitle}>{title}</h3>
          </div>
        </div>
      </section>
  );
};
