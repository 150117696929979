import React from "react";
import { Switch, Route } from 'react-router';

import {App, About} from './Pages'

export const Router = () => (
  <main>
    <Switch>
      <Route exact path='/' component={App} />
      <Route path='/about' component={About} />
    </Switch>
  </main>
)

export default Router;