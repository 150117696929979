import React, { Component } from "react";
import classNames from "classnames";
import * as styles from "./Page.module.scss";

import { general, projects } from "./content";
import { Footer } from "../Components";

class App extends Component {
  render() {
    const blockWithSecondaryBackground = classNames(
      styles.Block,
      styles.withSecondaryBackground
    );
    return (
      <div className={styles.App}>
        <div className={styles.HeaderSpace} />
        <section className={styles.Block}>
          <div className={styles.BlockContent}>
            <h1 className={styles.LargeTitle}>{general.title}</h1>
            <div className={styles.HeaderContent}>
              <img src={general.headerImage} alt="profile" />
              <div className={styles.Bio}>
                <p>{general.shortBio}</p>
                <div className={styles.ForwardNav}>
                  <a href="/about">
                    <img src="/right.png" />
                    <h3>About me</h3>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={blockWithSecondaryBackground}>
          <div className={styles.BlockContent}>
            <div className={styles.Tag}>
              <p>PROJECTS</p>
            </div>
            {projects.map(project => (
              <Project title={project.title} url={project.url}>
                {project.description}
              </Project>
            ))}
          </div>
        </section>
        <Footer contact={general.contact} />
      </div>
    );
  }
}

const Project = ({ title, url, children }) => {
  return (
    <>
      <h2 className={styles.ProjectTitle}>{title}</h2>
      <h3 className={styles.ProjectLink}>
        <a href={url}>{url.slice(2)}</a>
      </h3>
      <p className={styles.narrowText}>{children}</p>
      <br />
    </>
  );
};

export default App;
